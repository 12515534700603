import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import RegalaSubito from "../../Atoms/Modals/RegalaSubito";
import Logo from "../../../assets/Logos/Logo";
import Punti from "../../../assets/Punti";

import Notifiche from "../../../assets/sidebar/Notifiche";
import Faq from "../../../assets/Icons/Faq";
import BottoneGrigio from "../../Atoms/Bottoni/BottoneGrigio";
import BottoneGrigio2 from "../../Atoms/Bottoni/BottoneGrigio2";
import Notifica from "../../Atoms/Notifica/Notifica";

function Navbar({ logged, user = null }) {
    const url = window.location.pathname.split("/")[1];
    const [search, setSearch] = useState("");

    useEffect(() => {
        // Crea un'istanza di URLSearchParams per analizzare i parametri della query string
        const params = new URLSearchParams(window.location.search);

        // Logga la query string completa per il debug
        console.log(params.toString());

        // Funzione di supporto per ottenere e decodificare i parametri
        function getDecodedParam(param) {
            const value = params.get(param);
            return value ? decodeURIComponent(value) : null;
        }

        // Gestisci il parametro "search" con un caso speciale per "null"
        const searchParam = getDecodedParam("search");
        setSearch(searchParam === "null" ? "" : searchParam || "");
    }, [user]);

    const handleSearch = (e) => {
        e.preventDefault();
        const encodedSearch = encodeURIComponent(search);
        window.location.href = encodedSearch
            ? `/?search=${encodedSearch}`
            : "/";
    };

    return (
        <header
            className={` ${
                (logged && url === "chisiamo") ||
                (!logged && url === "") ||
                url === "localheroes" ||
                url === "login" ||
                url === "register" ||
                url === "recupero"
                    ? "hidden"
                    : "block" // TODO: sistemare
            } fixed top-0 h-navbar-mobile md:h-navbar-web pr-4 md:bg-background bg-white md:shadow-none shadow-md z-50 w-full flex flex-col justify-center items-center`}
        >
            <div className="w-full flex justify-between">
                <div className="md:hidden flex w-full items-center justify-between">
                    <div
                        className="w-40 ml-4 cursor-pointer"
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        <Logo />
                    </div>
                    {user ? (
                        <div className="flex justify-start items-center space-x-2">
                            <div
                                id="badgePuntiNavbar"
                                className="flex bg-background rounded-full h-6 pr-2 items-center space-x-2"
                            >
                                <div className="w-6 h-6">
                                    <Punti />
                                </div>
                                <p className="text-md font-semibold">
                                    {user.punti}
                                </p>
                            </div>
                            <div
                                id="notificheNavbar"
                                onClick={() =>
                                    (window.location.href = "/notifications")
                                }
                                className="mr-4 text-bianco flex justify-center items-center rounded-lg cursor-pointer"
                            >
                                <Notifica user={user} />
                            </div>
                        </div>
                    ) : (
                        <div className="flex space-x-2">
                            <button
                                onClick={() =>
                                    (window.location.href = "/login")
                                }
                                className="px-2 py-1 rounded-lg bg-verdeScuro border border-transparent text-white"
                            >
                                Accedi
                            </button>
                            <button
                                onClick={() =>
                                    (window.location.href = "/login")
                                }
                                className="px-2 py-1 rounded-lg border border-verdeScuro text-verdeScuro"
                            >
                                Iscriviti
                            </button>
                        </div>
                    )}
                </div>
                <div className="hidden md:flex items-center">
                    <div
                        className="flex w-sidebar justify-center cursor-pointer"
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        <div className="w-40">
                            <Logo />
                        </div>
                    </div>
                    <form
                        id="searchNavbarDesktop"
                        className="flex items-center ml-4 mr-8"
                        onSubmit={handleSearch}
                    >
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.currentTarget.value)}
                            onInput={(e) => setSearch(e.currentTarget.value)}
                            type="search"
                            className="w-96 md:bg-white  placeholder:text-sm focus:ring-verde md:ring-none ring-verde border-0 appearance-none rounded-lg placeholder:text-verdeScuro/50"
                            placeholder="Cerca oggetti in regalo"
                        />
                        <button
                            type="submit"
                            className="bg-transparent text-bianco flex justify-center items-center p-2 rounded-lg w-10 cursor-pointer"
                        >
                            <IoSearch className="w-6 h-6 fill-black " />
                        </button>
                    </form>
                    <div className="flex justify-end">
                        {user && <RegalaSubito user={user} logged={logged} />}
                    </div>
                </div>
                <div className="md:flex hidden justify-end">
                    {!logged ? (
                        <div className="flex justify-stretch items-center gap-2 mr-8">
                            <BottoneGrigio
                                text={"Accedi"}
                                onclick={() =>
                                    (window.location.href = "/login")
                                }
                            />
                            <BottoneGrigio2
                                text={"Iscriviti"}
                                onclick={() =>
                                    (window.location.href = "/login")
                                }
                            />
                        </div>
                    ) : (
                        <div
                            id="notificaNavbarDesktop"
                            onClick={() =>
                                (window.location.href = "/notifications")
                            }
                            className="mr-4 text-bianco flex justify-center items-center rounded-lg cursor-pointer"
                        >
                            <Notifica user={user} />
                        </div>
                    )}

                    <div
                        onClick={() => (window.location.href = "/faq")}
                        className="  text-bianco flex justify-center items-center rounded-lg  cursor-pointer"
                    >
                        <div className="text-grigio">
                            <Faq />
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:hidden flex pl-4 pt-3 w-full">
                <form
                    id="searchNavbar"
                    className="flex items-center w-full "
                    onSubmit={handleSearch}
                >
                    <input
                        value={search}
                        onChange={(e) => setSearch(e.currentTarget.value)}
                        onInput={(e) => setSearch(e.currentTarget.value)}
                        type="search"
                        className="w-full bg-background placeholder:text-sm focus:ring-verde md:ring-none ring-verde border-0 appearance-none rounded-lg placeholder:text-verdeScuro/50"
                        placeholder="Cerca oggetti in regalo"
                    />
                    <button
                        type="submit"
                        className="bg-transparent text-bianco flex justify-center items-center pl-2 rounded-lg  cursor-pointer"
                    >
                        <IoSearch className="w-6 h-6 fill-black " />
                    </button>
                </form>
            </div>
        </header>
    );
}

export default Navbar;
