import React from "react";

function Bottone({ children, onClick, textStyle, bgColor, border, fullWidth, disabled, hoverColor, height=12, href }) {
	const className = `py-2 px-4 text-center cursor-pointer
                ${textStyle || "text-white"} 
                ${disabled ? "border-none" : border} 
                ${disabled ? "bg-grigio" : bgColor || "bg-verde"}
				${!disabled && hoverColor || ""} //"hover:bg-verdeScuro"}
				rounded-lg flex items-center justify-center ${fullWidth && 'w-full'}
				${height && `h-${height}`}`
	if (href) {
		return (
			<a
				href={href}
				// target="_blank"
				// rel="noreferrer"
				className={className}>
				{children}
			</a>
		)
	}
	return (
		<button
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={className}>
			{children}
		</button>
	);
}

export default Bottone;
