import React, { useState } from "react";
import Benvenuto from "./Benvenuto";
import CodiceBenvenuto from "./CodiceBenvenuto";
import PuntiGuadagnati from "./PuntiGuadagnati";
import AccettaNotifiche from "./AccettaNotifiche";
import InserisciLocalita from "./InserisciLocalita";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import useFirstLoginStore from "../../../stores/firstLoginStore";

const DrawersPrimoLogin = ({ user, onFinished }) => {
    const handleDrawerClose = async (e) => {
        setIndiceAttuale(indiceAttuale + 1);

        if (indiceAttuale === drawersStack.length - 1) {
            const docRef = doc(db, "users", user.id);

            console.log(user.id);
            try {
                await updateDoc(docRef, {
                    firstLogin: false,
                });
                console.log("First login updated");
            } catch (e) {
                console.log(e);
            }

            setFinished(true);
            onFinished();
        }
    };

    const drawersStack = [
        <CodiceBenvenuto onClose={(e) => handleDrawerClose(e)} />,
        <PuntiGuadagnati onClose={(e) => handleDrawerClose(e)} />,
        <Benvenuto onClose={(e) => handleDrawerClose(e)} />,

        <InserisciLocalita onClose={(e) => handleDrawerClose(e)} />,
    ];

    const firstLogin = useFirstLoginStore((state) => state.firstLogin);
    const [indiceAttuale, setIndiceAttuale] = useState(0);
    const [finished, setFinished] = useState(false);

    return (
        <div className={`${finished ? "hidden" : ""}`}>
            {firstLogin && !finished && drawersStack[indiceAttuale]}
        </div>
    );
};

export default DrawersPrimoLogin;

/*
<AccettaNotifiche onClose={(e) => handleDrawerClose(e)} />,
*/
