import React, { useEffect, useState } from "react";
import Punti from "../../../assets/Punti";
import { db } from "../../../firebase";
import {
    arrayRemove,
    arrayUnion,
    doc,
    getDoc,
    onSnapshot,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { AsyncImage } from "loadable-image";
import { calcolaDistanza } from "../../../utils/calcolaDistanza";
import { ToggleFavourite } from "../Bottoni/ToggleButtons";

function CardPublic({ props, chiave = 0, user = null, dash = false }) {
    const img = props.img.length
        ? props.img[0].length
            ? props.img[0]
            : props.img[1].length
                ? props.img[1]
                : props.img[2].length
                    ? props.img[2]
                    : props.img[3].length
                        ? props.img[3]
                        : props.img[4].length
                            ? props.img[4]
                            : props.img[5]
                                ? props.img[5]
                                : require("../../../assets/default/default-image.jpg")
        : require("../../../assets/default/default-image.jpg");

    const [Distanza, setDistanza] = useState(0);
    const [liked, setLiked] = useState(false);
    const [owner, setOwner] = useState("");

    const handleFavourite = async () => {
        console.log("user id", user.id);

        const docRef = doc(db, "preferiti", user.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data(); // Lista di ID

            if (data.list.includes(props.id)) {
                // Rimuovi l'ID se è già presente
                await updateDoc(docRef, {
                    list: arrayRemove(props.id),
                });
            } else {
                // Aggiungi l'ID se non è presente
                await updateDoc(docRef, {
                    list: arrayUnion(props.id),
                });
            }
        } else {
            // Crea un nuovo documento con la lista contenente l'ID
            await setDoc(docRef, {
                list: [props.id],
            });
        }
    };

    const getUser = async () => {
        try {
            const docRef = doc(db, "users", props.owner);
            const docData = await getDoc(docRef);
            if (docData.exists()) {
                setOwner(docData.data().nome);
                user &&
                    setDistanza(
                        await calcolaDistanza(
                            user.lat || 45.4628245,
                            user.lng || 9.1076926,
                            docData.data().lat,
                            docData.data().lng
                        ));
            }
        } catch (error) { }
    };

    const fetchLiked = () => {
        if (!user) {
            return false;
        }

        const docRef = doc(db, "preferiti", user.id);

        const unsubscribe = onSnapshot(
            docRef,
            (docSnap) => {
                if (docSnap.exists()) {
                    setLiked(docSnap.data().list.includes(props.id));
                }
            },
            (error) => {
                console.error("Error fetching liked document:", error);
            }
        );

        // Restituisce la funzione di "unsubscription" per permettere di smettere di ascoltare gli aggiornamenti quando necessario
        return unsubscribe;
    };

    useEffect(() => {
        getUser();
        fetchLiked();
    }, [chiave]);

    return (
        <div>
            <div className=" md:min-w-[15rem] md:max-w-[18rem]  w-full bg-white  rounded-lg border shadow-md border-gray-200 relative transition-all duration-200 hover:scale-[98%]">
                <div className="absolute z-10 bottom-0 right-0 backdrop-blur m-2 p-1 w-10 aspect-square flex justify-center items-center">
                    <ToggleFavourite isSelected={liked} onClick={handleFavourite} />
                </div>
                
                <div
                    className="flex relative items-center justify-center"
                    onClick={() =>
                    (window.location.href = !dash
                        ? "/prodotto/" + props.id
                        : "/dashboard/" + props.id)
                    }
                >
                    <AsyncImage
                        className="w-full md:h-40 h-36 object-cover rounded-t-lg cursor-pointer"
                        src={img}
                        alt={props.titolo}
                    />
                    <div className="absolute flex space-x-2 left-2 bottom-2">
                        {props.prenotati.length ? (
                            <div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-verde">
                                <p>
                                    {props.prenotati.length} Prenotat
                                    {props.prenotati.length === 1 ? "o" : "i"}
                                </p>
                            </div>
                        ) : (
                            <div className="absolute"></div>
                        )}
                        {props.ricevuto && props.consegnato ? (
                            <div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-rosso">
                                <p>Regalato</p>
                            </div>
                        ) : props.assegnato != null ? (
                            <div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-verdeScuro">
                                <p>Assegnato</p>
                            </div>
                        ) : (
                            <div className="absolute"></div>
                        )}
                    </div>
                </div>
                <div className="flex md:p-4 p-1 flex-col  md:space-y-3 space-y-0 md:mt-0 mt-1">
                    <h5
                        onClick={() =>
                        (window.location.href = !dash
                            ? "/prodotto/" + props.id
                            : "/dashboard/" + props.id)
                        }
                        className="text-md cursor-pointer md:block hidden font-semibold tracking-tight text-black "
                    >
                        {props.titolo.slice(0, 25)}
                        {props.titolo.length > 25 && "..."}
                    </h5>
                    <h5
                        onClick={() =>
                        (window.location.href = !dash
                            ? "/prodotto/" + props.id
                            : "/dashboard/" + props.id)
                        }
                        className="text-md cursor-pointer pl-1 block md:hidden font-medium tracking-tight text-black "
                    >
                        {props.titolo.slice(0, 13)}
                        {props.titolo.length > 13 && "..."}
                    </h5>
                    <div className="flex space-x-1 md:text-sm text-xs  items-center md:pl-0 pl-1">
                        {owner}
                        {user && <p className="text-sm">, {Distanza} Km</p>}
                    </div>
                </div>
                <div className="md:px-4 px-2 md:mt-0 mt-2 pr-2 pb-4 cursor-pointer flex justify-start items-center">
                    <div
                        onClick={() =>
                        (window.location.href = !dash
                            ? "/prodotto/" + props.id
                            : "/dashboard/" + props.id)
                        }
                        className="flex items-center justify-between"
                    >
                        <span className="md:text-xl text-md flex items-center space-x-2 md:font-bold font-semibold text-black ">
                            <div className="md:w-7 w-6">
                                <Punti />
                            </div>
                            <p>100</p>{" "}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardPublic;
