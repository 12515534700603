import React, { useState } from 'react'
import StepProgress from './StepProgress';
import Bottone from '../../Atoms/Bottoni/Bottone';
import LoadingBtn from '../../Atoms/Bottoni/LoadingBtn';
import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";

const Wizard = ({ steps, showStep, isNextAllowed, isPrevAllowed, onCompleted, completaText = "Completa" }) => {
    const [step, setStep] = useState(0);
    const [defaultButtonsHidden, setDefaultButtonsHidden] = useState(false);

    const nextStep = () => {
        setStep(step + 1);
        setDefaultButtonsHidden(false);
    };
    const prevStep = () => {
        setStep(step - 1);
        setDefaultButtonsHidden(false);
    };

    const StepComponent = steps[step]
    const backButtonClassName = 'relative flex items-center top-0 left-0 w-auto z-10 text-3xl text-verdeScuro';

    return (
        <div className='flex flex-col h-full space-y-5'>
            {step > 0 && isPrevAllowed &&
                <button
                    className={backButtonClassName}
                    onClick={prevStep}
                >
                    <div className='w-6'>
                        <Iconfrecciagiu />
                    </div>
                    <span className='text-lg'>Indietro</span>
                </button>
            }
            {step === 0 && isPrevAllowed &&
                <a
                    href='/login'
                    className={backButtonClassName}
                >
                    <div className='w-6'>
                        <Iconfrecciagiu />
                    </div>
                    <span className='text-lg'>Indietro</span>
                </a>
            }
            {showStep && <StepProgress containerStyle="w-3/4 space-x-2" progressive stepLenght={steps.length} currentStep={step} />}
            <div className='flex-1'>
                <StepComponent
                    nextStep={step < steps.length - 1 ? nextStep : onCompleted}
                    prevStep={step > 0 && prevStep}
                    setDefaultButtonsHidden={setDefaultButtonsHidden} />
            </div>
            {
                !defaultButtonsHidden &&
                <div className='flex flex-col md:flex-row-reverse justify-between md:space-y-0 md:space-x-5 md:space-x-reverse'>
                    {step < steps.length - 1 &&
                        <Bottone textStyle={"font-semibold text-white"} disabled={!isNextAllowed} fullWidth onClick={nextStep}>Prosegui</Bottone>
                    }
                    {step === steps.length - 1 &&
                        <LoadingBtn textStyle={"font-semibold text-white"} disabled={!isNextAllowed} fullWidth onClick={onCompleted}>{completaText}</LoadingBtn>
                    }
                </div>}

        </div>
    )
}

export default Wizard