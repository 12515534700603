import { create } from "zustand";

const useFirstLoginStore = create((set, get) => ({
    firstLogin: false, // provvisiorio
    codiceBenvenuto: "",
    codiceBenvenutoValido: false,
    errore: "",
    // fetchLoginInfo: async (user) => {
    //     set({ firstLogin: user.firstlogin });
    // },
    setFirstLogin: (value) => set({ firstLogin: value }), //provvisorio
    setCodiceBenvenuto: (value) => set({ codiceBenvenuto: value }),
    checkCodiceBenvenuto: async () => {
        const codiceBenvenuto = get().codiceBenvenuto;
        // const isValid = await checkCodiceBenvenuto(codiceBenvenuto);
        if (codiceBenvenuto === "123456")
            set({ codiceBenvenutoValido: true });
        else {
            set({ errore: "Codice non valido! Riprova." });
        }
    }
}));

export default useFirstLoginStore;