import React, { useEffect, useState } from "react";
import Logo from "../../../assets/Logos/Logo";
import BottoneGrigio from "../../Atoms/Bottoni/BottoneGrigio";
import BottoneGrigio2 from "../../Atoms/Bottoni/BottoneGrigio2";
import Home from "../../../assets/sidebar/Home";
import Esci from "../../../assets/sidebar/Esci";
import Notifiche from "../../../assets/sidebar/Notifiche";
import Messaggi from "../../../assets/sidebar/Messaggi";
import Preferiti from "../../../assets/sidebar/Preferiti";
import PreferitiOutline from "../../../assets/sidebar/PreferitiOutline";
import HomeOutline from "../../../assets/sidebar/HomeOutline";
import EsciOutline from "../../../assets/sidebar/EsciOutline";
import NotificheOutline from "../../../assets/sidebar/NotificheOutline";
import MessaggiOutline from "../../../assets/sidebar/MessaggiOutline";
import ProfilePic from "../../Atoms/ProfilePic/ProfilePic";
import Punti from "../../../assets/Punti";

function Sidebar({ logged, user, loaded }) {
    //console.log(user);

    const [url, setUrl] = useState(
        window.location.pathname.split("/")[0] + "/"
    );

    const elements = [
        {
            title: "Home",
            icon: () => <Home />,
            iconOutline: () => <HomeOutline />,
            url: "/",
        },

        {
            title: "Messaggi",
            icon: () => <Messaggi />,
            iconOutline: () => <MessaggiOutline />,
            url: "/chat/",
        },
        {
            title: "Preferiti",
            icon: () => <Preferiti />,
            iconOutline: () => <PreferitiOutline />,
            url: "/favourites/",
        },

        {
            title: "Esci",
            icon: () => <Esci />,
            iconOutline: () => <EsciOutline />,
            url: "/logout/",
        },
    ];

    useEffect(() => {
        setUrl(window.location.pathname.split("/")[1]);
    }, []);

    return (
        <div
            id="sidebar"
            className={`fixed bg-background pt-2 w-sidebar pl-3 pr-1`}
        >
            {logged ? (
                <a
                    href="/profilo"
                    className="flex space-x-3 rounded-lg hover:bg-verdeScuro/5 justify-start cursor-pointer p-2 items-center "
                >
                    <div className="relative">
                        <ProfilePic w={16} userId={user.id} />
                    </div>
                    <div>
                        <p>{user.nome} </p>
                        <div className="flex justify-normal">
                            <div id="badgePuntiSidebar" className="flex bg-white rounded-full  mt-2 pr-2 justify-start space-x-2">
                                <div className="w-7">
                                    <Punti />
                                </div>
                                <p className="text-xl font-semibold">
                                    {user.punti}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            ) : (
                <div className=""></div>
            )}
            <div className="mt-5 flex pl-3  flex-col space-y-5">
                {elements.map((element, i) => {
                    if (element.title === "Esci") {
                        if (logged) {
                            return (
                                <div
                                    onClick={() =>
                                        (window.location.href = element.url)
                                    }
                                    key={i}
                                    className={` 
                                        
                                             bg-transparent
                                     flex items-center cursor-pointer p-2 rounded-lg space-x-2 hover:bg-verdeScuro/10`}
                                >
                                    {" "}
                                    {url === element.url.split("/")[1] ? (
                                        <div
                                            onClick={() =>
                                                (window.location.href =
                                                    element.url)
                                            }
                                            className={`${
                                                url ===
                                                element.url.split("/")[1]
                                                    ? "block"
                                                    : "hidden"
                                            } text-white`}
                                        >
                                            {element.icon()}
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() =>
                                                (window.location.href =
                                                    element.url)
                                            }
                                            className={`${
                                                url ===
                                                element.url.split("/")[1]
                                                    ? "hidden"
                                                    : "block"
                                            } text-blakc`}
                                        >
                                            {element.iconOutline()}
                                        </div>
                                    )}
                                    <p
                                        className={` ${
                                            url === element.url.split("/")[1]
                                                ? "text-verdeScuro font-semibold"
                                                : "text-verdeScuro "
                                        } `}
                                    >
                                        {element.title}
                                    </p>
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div
                                onClick={() =>
                                    (window.location.href = element.url)
                                }
                                key={i}
                                className={` ${
                                    url === element.url.split("/")[1]
                                        ? "bg-transparent"
                                        : "bg-transparent"
                                } flex items-center cursor-pointer p-2 rounded-lg space-x-2 hover:bg-verdeScuro/10`}
                            >
                                {" "}
                                {url === element.url.split("/")[1] ? (
                                    <div
                                        onClick={() =>
                                            (window.location.href = element.url)
                                        }
                                        className={`${
                                            url === element.url.split("/")[1]
                                                ? "block"
                                                : "hidden"
                                        } text-verdeScuro`}
                                    >
                                        {element.icon()}
                                    </div>
                                ) : (
                                    <div
                                        onClick={() =>
                                            (window.location.href = element.url)
                                        }
                                        className={`${
                                            url === element.url.split("/")[1]
                                                ? "hidden"
                                                : "block"
                                        } text-verdeScuro`}
                                    >
                                        {element.iconOutline()}
                                    </div>
                                )}
                                <p
                                    className={` ${
                                        url === element.url.split("/")[1]
                                            ? "text-verdeScuro font-semibold"
                                            : "text-verdeScuro "
                                    } `}
                                >
                                    {element.title}
                                </p>
                            </div>
                        );
                    }
                })}
            <a className="pt-3 pl-3 text-sm font-light text-grigio" href="/chisiamo">Chi siamo?</a>
            <a className=" pl-3 text-sm font-light text-grigio" href="/faq">Come funziona?</a>
            </div>
        </div>
    );
}

export default Sidebar;
