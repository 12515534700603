import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiCheck, FiLoader, FiX } from "react-icons/fi";

function LoadingBtn({ children, onClick, textStyle, bgColor, border, fullWidth, disabled, hoverColor, height=12, success=null, href }) {
	const [variant, setVariant] = useState("neutral");

    //className di Adolfo
    const className = `py-2 px-4 text-center cursor-pointer 
    ${textStyle || "text-white"} 
    ${disabled ? "border-none" : border} 
    ${!disabled && hoverColor || ""} //"hover:bg-verdeScuro"}
    rounded-lg flex items-center justify-center ${fullWidth && 'w-full'}
    ${height && `h-${height}`}`

	useEffect(()=>{
		
		if(success === true) setVariant("success")
		if(success === false) setVariant("error")
			setTimeout(()=>{
				setVariant("neutral")
			},5000);
	},[success])

    // className di Hover, andrebbero fuse per cambiare lo sfondo (ecc...) a seconda dello stato
	const classNames =
		variant === "neutral"
			? "bg-verde hover:bg-verdeScuro"
			: variant === "error"
			? "bg-rosso"
			: variant === "success"
			? "bg-verde"
			: "bg-verdeScuro pointer-events-none";

	

	return (
		<motion.button
			disabled={variant !== "neutral"}
			onClick={()=>{onClick(); setVariant("loading")}}
			className={`relative rounded-md px-4 py-2 font-medium text-white transition-all ${className} ${classNames}`}
		>
			<motion.span
				animate={{
					y: variant === "neutral" ? 0 : 6,
					opacity: variant === "neutral" ? 1 : 0,
				}}
				className="inline-block"
			>
				{children}
			</motion.span>
			<IconOverlay Icon={FiLoader} visible={variant === "loading"} spin />
			<IconOverlay Icon={FiX} visible={variant === "error"} />
			<IconOverlay Icon={FiCheck} visible={variant === "success"} />
		</motion.button>
	);
}

const IconOverlay = ({ Icon, visible, spin = false }) => {
	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					initial={{
						y: -12,
						opacity: 0,
					}}
					animate={{
						y: 0,
						opacity: 1,
					}}
					exit={{
						y: 12,
						opacity: 0,
					}}
					className="absolute inset-0 grid place-content-center"
				>
					<Icon className={`text-xl duration-300 ${spin && "animate-spin"}`} />
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default LoadingBtn;
