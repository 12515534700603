import { auth, db, messaging } from "./firebase";
import {
    useEffect,
    useState,
    lazy,
    Suspense,
    createContext,
    useReducer,
    useContext,
} from "react";
import Cookies from "universal-cookie";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Caricamento from "./Components/Atoms/Caricamento/Caricamento";
import Sidebar from "./Components/Molecules/Sidebar/Sidebar";
import Navbar from "./Components/Molecules/Sidebar/Navbar";
import Footer from "./Components/Molecules/Footer/Footer";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    updateDoc,
} from "firebase/firestore";
import Preferiti from "./Pages/Preferiti/Preferiti";
import ProdottoAperto from "./Pages/Prodotto/ProdottoAperto";
import Search from "./Pages/Search/Search";
import Profili from "./Pages/Profili/Profili";
import globals from "./utils/Globals";
import DrawersPrimoLogin from "./Components/Atoms/Modals/DrawersPrimoLogin";
import React from "react";
import Registrato from "./Pages/Registrato/Registrato";
import VerificaMail from "./Components/Atoms/Modals/VerificaMail";
import Funziona from "./Pages/Funziona/Funziona";
import Chisiamo from "./Pages/Chisiamo/Chisiamo";
import Problemi from "./Pages/Problemi/Problemi";
import { getToken } from "firebase/messaging";
import { rootReducer, ReducerContext, initialState } from "./rootReducer";
import useDeviceDetection from "./utils/useDeviceDetection";
import useUserStore from "./stores/userStore";
import useFirstLoginStore from "./stores/firstLoginStore";
import RecuperoPassword from "./Pages/RecuperoPassword/RecuperoPassword";
import { Toaster } from "sonner";

import { getHomeTutorial } from "./Components/Molecules/Tutorial/tutorials";
import Facciata from "./Pages/Landing/Facciata";
import LocalHeroes from "./Pages/Landing/LocalHeroes/LocalHeroes";

// Importazioni lazy

const Registration = lazy(() => import("./Pages/Register/SteppedRegistration"));
const Home = lazy(() => import("./Pages/Home/Home"));
const ProdottoApertoP = lazy(() => import("./Pages/Prodotto/ProdottoApertoP"));
const Chat = lazy(() => import("./Pages/Chat/Chat"));
const Notifications = lazy(() => import("./Pages/Notifications/Notifications"));
const Profilo = lazy(() => import("./Pages/Profilo/Profilo"));
const Login = lazy(() => import("./Pages/Login/Login"));
const Community = lazy(() => import("./Pages/Community/Community"));
const Exit = lazy(() => import("./Components/Atoms/Exit"));
const SidebarPhone = lazy(() =>
    import("./Components/Molecules/Sidebar/SidebarPhone")
);

export const Provider = ({ children, state, dispatch }) => {
    return (
        <ReducerContext.Provider value={{ state, dispatch }}>
            {children}
        </ReducerContext.Provider>
    );
};

function App() {
    const [logged, setLogged] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);
    const setFirstLogin = useFirstLoginStore((state) => state.setFirstLogin);
    const setNome = useFirstLoginStore((state) => state.setNome);
    const cookies = new Cookies();

    const [state, dispatch] = useReducer(rootReducer, initialState);
    const device = useDeviceDetection();
    const isMobile = device === "Mobile";

    useEffect(() => {
        // Il listener viene chiamato ogni volta che lo stato di autenticazione cambia
        const unsubscribe = auth.onAuthStateChanged(async (utente) => {
            try {
                if (utente) {
                    const docRef = doc(db, "users", utente.uid);
                    const docData = await getDoc(docRef);

                    console.log(window.location.pathname);

                    if (docData.exists()) {
                        setLogged(true);
                        const livello =
                            docData.data().livello && docData.data().livello > 0
                                ? docData.data().livello
                                : 0;
                        const coordinate = cookies.get("coordinate")
                            ? cookies.get("coordinate")
                            : docData.data().lat
                            ? {
                                  lat: docData.data().lat,
                                  lng: docData.data().lng,
                                  comune: docData.data().comune,
                              }
                            : {
                                  lat: 45.4628245,
                                  lng: 9.0951606,
                                  comune: "Milano",
                              };
                        setUser({
                            ...docData.data(),
                            id: utente.uid,
                            livello: livello,
                            lat: coordinate.lat,
                            lng: coordinate.lng,
                            comune: coordinate.comune,
                        });

                        setFirstLogin(docData.data().firstLogin);
                        setNome(docData.data().nome);
                        console.log("Livello: ", user);
                        globals.user = {
                            ...docData.data(),
                            id: utente.uid,
                            livello: livello,
                        };
                    }
                } else {
                    // Utente non autenticato
                    setUser(null);
                    setLogged(false);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoaded(true);
            }
        });

        // Pulizia del componente per evitare perdite di memoria
        return () => unsubscribe();
    }, []);

    // Disabilita la selezione di testo su tutti gli elementi eccetto h1, h2, h3, h4, h5, h6, p, a, span, li, label
    document.addEventListener("DOMContentLoaded", function () {
        const elements = document.querySelectorAll(
            "*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(p):not(a):not(span):not(li):not(label)"
        );
        elements.forEach((element) => {
            element.classList.add("no-select");
        });
    });

    ////////////////////////////////////// CONSOLE CLEAR ******************************************************
    useEffect(() => {
        //console.clear();
    });
    const fullScreen = logged
        ? ["login", "register", "chisiamo", "localheroes"].includes(
              window.location.pathname.split("/")[1]
          )
            ? true
            : false
        : ["login", "register", "", "localheroes", "recupero"].includes(
              window.location.pathname.split("/")[1]
          )
        ? true
        : false;

    const RedirectToBrowser = ({ url }) => {
        useEffect(() => {
            // Controlla se l'utente è su Instagram
            if (navigator.userAgent.match(/Instagram/i)) {
                const userAgent =
                    navigator.userAgent || navigator.vendor || window.opera;
                if (/android/i.test(userAgent)) {
                    // Per Android, usa Intent
                    window.location.href = `intent://${url.replace(
                        "https://",
                        ""
                    )}#Intent;scheme=https;package=com.android.chrome;end`;
                } else if (
                    /iPad|iPhone|iPod/.test(userAgent) &&
                    !window.MSStream
                ) {
                    // Per iOS, usa un link speciale
                    window.location.href = `itms://togiftit.com`;
                    setTimeout(() => {
                        window.location.href = url;
                    }, 500);
                } else {
                    // Altrimenti, prova semplicemente ad aprire il link
                    window.location.href = url;
                }
            } else {
                // Se non siamo su Instagram, reindirizza direttamente
                window.location.href = url;
            }
        }, [url]);

        return <div></div>;
    };

    return (
        <Provider state={state} dispatch={dispatch}>
            
            <div className="flex flex-col min-h-[100lvh]">
                <Toaster position="top-center" richColors />
                <Navbar user={user} logged={logged} />
                <div
                    className={`${
                        !fullScreen &&
                        "mt-navbar-mobile md:mt-navbar-web mb-bottombar-phone md:mb-0"
                    } ${
                        ((logged &&
                            ["chisiamo", "localheroes"].includes(
                                window.location.pathname.split("/")[1]
                            )) ||
                            (!logged &&
                                ["", "localheroes"].includes(
                                    window.location.pathname.split("/")[1]
                                ))) &&
                        "mt-[5rem]"
                    } flex-1  overflow-auto`}
                >
                    {loaded && (
                        <div
                            className={`${!fullScreen ? "md:block" : "hidden"}`}
                        >
                            <div className="md:block hidden">
                                <Sidebar
                                    logged={logged}
                                    user={user}
                                    loaded={loaded}
                                />
                            </div>
                        </div>
                    )}
                    {loaded ? (
                        <div
                            className={`${
                                !fullScreen && "h-full px-4 md:ml-sidebar"
                            }`}
                        >
                            <BrowserRouter>
                                {logged ? (
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <Home
                                                    logged={logged}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/prodotto/:id"
                                            element={
                                                <ProdottoApertoP
                                                    user={user}
                                                    logged={logged}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/dashboard/:id"
                                            element={
                                                <ProdottoAperto user={user} />
                                            }
                                        />
                                        <Route
                                            path="/chat/:chat"
                                            element={<Chat />}
                                        />
                                        <Route
                                            path="/chat"
                                            element={<Chat />}
                                        />
                                        <Route
                                            path="/notifications"
                                            element={<Notifications />}
                                        />
                                        <Route
                                            path="/favourites"
                                            element={
                                                <Preferiti
                                                    user={user}
                                                    userId={user.id}
                                                />
                                            }
                                        />

                                        <Route
                                            path="/profilo"
                                            element={<Profilo user={user} />}
                                        />
                                        <Route
                                            path="/profilo/:id"
                                            element={<Profili user={user} />}
                                        />
                                        <Route
                                            path="/search"
                                            element={<Search user={user} />}
                                        />

                                        <Route
                                            path="/logout"
                                            element={<Exit />}
                                        />
                                        <Route
                                            path="/registrato"
                                            element={<Registrato user={user} />}
                                        />
                                        <Route
                                            path="/faq"
                                            element={<Funziona />}
                                        />
                                        <Route
                                            path="/chisiamo"
                                            element={
                                                <Facciata logged={logged} />
                                            }
                                        />
                                        <Route
                                            path="/register"
                                            element={<Registration />}
                                        />
                                        <Route
                                            path="/localheroes"
                                            element={<LocalHeroes />}
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate to="/" replace />
                                            }
                                        />
                                    </Routes>
                                ) : (
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <Facciata logged={logged} />
                                            }
                                        />
                                        <Route
                                            path="/webapp"
                                            element={<Home />}
                                        />
                                        <Route
                                            path="/localheroes"
                                            element={<LocalHeroes />}
                                        />

                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="/register"
                                            element={<Registration />}
                                        />
                                        <Route
                                            path="/faq"
                                            element={<Funziona />}
                                        />
                                        <Route
                                            path="/chisiamo"
                                            element={<Chisiamo />}
                                        />
                                        <Route
                                            path="/search"
                                            element={<Search />}
                                        />
                                        <Route
                                            path="/problemi"
                                            element={<Problemi />}
                                        />
                                        <Route
                                            path="/recupero"
                                            element={<RecuperoPassword />}
                                        />
                                        <Route
                                            path="/prodotto/:id"
                                            element={<ProdottoApertoP />}
                                        />
                                        <Route
                                            path="/registrato"
                                            element={<Registrato user={user} />}
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate to="/login" replace />
                                            }
                                        />
                                    </Routes>
                                )}
                            </BrowserRouter>
                            <Footer />
                            {logged && (
                                <DrawersPrimoLogin
                                    user={user}
                                    onFinished={() =>
                                        getHomeTutorial(isMobile).drive()
                                    }
                                />
                            )}
                        </div>
                    ) : (
                        <div className="h-[100vh]">
                            <Caricamento />
                        </div>
                    )}
                </div>
                <div className="fixed md:hidden block z-50 w-full bottom-0 left-0">
                    <SidebarPhone user={user} logged={logged} />
                </div>
            </div>
        </Provider>
    );
}

export default App;

const Redirect = ({ path }) => {
    window.location.href = path;
    return <Navigate to={path} replace />;
};
